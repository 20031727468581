import * as React from 'react'
import styled from 'styled-components'

import SubscriptionCard from '../subscription/subscriptionCard'
import { Input } from '../styled/input'
import { Button } from '../styled/button'
import axios from 'axios'
import { config } from '../../config'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    margin: 8px 0;
    flex: 1 1 80%;
  }
  > button {
    width: 100%;
    margin: calc(var(--spacing) * 3) 0;
  }
`

const ChangePassword = ({ onClose }) => {
  const [data, setData] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = React.useState({})

  const validate = () => {
    let e = {}
    if (!data.password) {
      e.password = 'Current password is required'
    }
    if (!data.new_password) {
      e.new_password = 'New password is required'
    }

    if (data.new_password && data.new_password !== data.confirm_new_password) {
      e.new_password = e.confirm_new_password = 'Passwords do not match'
    }

    setErrors(e)
    return Object.keys(e).length === 0
  }

  const changePassword = async () => {
    if (!validate()) {
      return
    }
    try {
      setLoading(true)
      const token = window.localStorage.getItem('token')
      await axios.post(`${config.API_BASE_URL}/users/password/change`, {
        ...data,
        token,
      })
      alert('Password changed')
      onClose()
    } catch (e) {
      alert('An error has occurred. Please try again')
    } finally {
      setLoading(false)
    }
  }
  const handleInputChange = e => {
    let d = { ...data }
    d[e.target.id] = e.target.value
    setData(d)
  }

  return (
    <SubscriptionCard
      titles={[
        { inverted: true, content: 'CHANGE' },
        { inverted: false, content: 'PASSWORD' },
      ]}
      isLeft={false}
      onClose={onClose}
    >
      <Container>
        <Input
          label="Current Password"
          id={'password'}
          onChange={handleInputChange}
          error={errors.password}
          type="password"
          placeholder="••••••••"
        />
        <Input
          label="New Password"
          id={'new_password'}
          onChange={handleInputChange}
          error={errors.new_password}
          type="password"
          placeholder="••••••••"
        />
        <Input
          label="Confirm New Password"
          id={'confirm_new_password'}
          onChange={handleInputChange}
          error={errors.confirm_new_password}
          type="password"
          placeholder="••••••••"
        />
        <Button disabled={loading} onClick={changePassword}>
          {loading ? 'Please wait..' : 'Change'}
        </Button>
      </Container>
    </SubscriptionCard>
  )
}

export default ChangePassword
