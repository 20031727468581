import * as React from 'react'
import styled, { css } from 'styled-components'
import Select, { components as SelectComponents } from 'react-select'

//ASSETS
import { PHONE_NUMBER_CODES } from '../../data/phoneCodes'
//COMPONENTS
import { Input } from './input'

const Container = styled.div`
  position: relative;
`

const customStyles = {
  container: () => ({
    position: 'initial',
  }),
  control: (styles, props) => ({
    ...styles,
    fontSize: '14px',
    position: 'absolute',
    left: '9px',
    bottom: '9px',
    padding: '0',
    borderRadius: '20px',
    backgroundColor: '#f9f9f9',
    boxShadow: 'none',
    cursor: 'pointer',
    width: '90px',
    border: props.isFocused ? '1px solid black' : '1px solid #bababa',
    ':hover': {
      border: props.isFocused ? '1px solid black' : '1px solid #bababa',
    },
  }),

  option: (styles, { isDisabled, isSelected }) => {
    return {
      ...styles,

      transition: 'all 0.2s',
      backgroundColor: isSelected ? '#f2fca1' : 'inherit',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      color: 'black',
      ':hover': {
        backgroundColor: '#f2fca1',
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#f2fca1' : '#f2fca1'),
      },
      borderRadius: '20px',
    }
  },
  menu: styles => {
    return {
      ...styles,
      borderRadius: '20px',
      margin: 0,
    }
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: styles => ({
    ...styles,
    color: '#bababa',
  }),
}
const SingleValue = ({ children, ...props }) => (
  <SelectComponents.SingleValue {...props}>
    {props.data.value}
  </SelectComponents.SingleValue>
)

const PhoneNumberInput = ({
  placeholder,
  label,
  value,
  code,
  phone,
  onCodeChange,
  onPhoneChange,
  id,
}) => {
  return (
    <Container>
      <Select
        options={PHONE_NUMBER_CODES}
        styles={customStyles}
        onChange={onCodeChange}
        isSearchable={false}
        value={code}
        onSelectResetsInput={false}
        placeholder="+374"
        components={{ SingleValue }}
      />
      <Input
        label={label}
        type="phone"
        id={id}
        value={phone}
        onChange={onPhoneChange}
        placeholder={placeholder}
        inputCss={css`
          padding-left: 110px;
        `}
      />
    </Container>
  )
}

export default PhoneNumberInput
