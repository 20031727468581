import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

// COMPONENTS
import { Button } from '../styled/button'
import { Textarea } from '../styled/textarea'

const Container = styled.div`
  textarea {
    height: calc(var(--spacing) * 36);
  }
`
const StyledButton = styled(Button)`
  margin-top: calc(var(--spacing) * 6);
  width: 100%;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  > button {
    flex-basis: 49%;
  }
`
const CancelSubscription = ({ onNo, onSubmit, user }) => {
  const [error, setError] = useState('')

  const [message, setMessage] = React.useState('')
  return (
    <Container>
      <Textarea
        value={message}
        error={error}
        onChange={e => setMessage(e.target.value)}
        placeholder="I didn't like the concept"
        label={`Hey ${user.firstName} ${user.lastName}, It is super sad to see that you are leaving. We really hope that you found something interesting while was part of our community. Can you provide feedback/explanation on why you are canceling this subscription? We are publishing in our weekly newsletter all the feedbacks and this will be super important for us.`}
      />
      <Buttons>
        <StyledButton secondary onClick={onNo}>
          No, Thank you
        </StyledButton>
        <StyledButton
          onClick={() => {
            if (!message) {
              setError('Please enter a message')
              return
            }
            setError('')
            onSubmit({ message })
          }}
        >
          Cancel now
        </StyledButton>
      </Buttons>
    </Container>
  )
}

export default CancelSubscription
