import * as React from 'react'

// COMPONENTS
import Layout from 'layouts/main'
import ProfileForm from 'components/profile/form'

const ProfilePage = () => (
  <Layout title="Profile">
    <ProfileForm />
  </Layout>
)

export default ProfilePage
