import styled from 'styled-components'
import { SCREEN } from '../../styles/screens'

export const Tabs = styled.div`
  display: none;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: flex;
    flex-grow: 1;
  }
`

export const Tab = styled.div`
  padding: 12px 20px;
  font-size: 14px;
  flex: 1 0 48%;
  margin: 0 2px;
  text-align: center;
  transition: box-shadow 0.4s;
  box-shadow: ${({ activeTab }) =>
    activeTab ? 'inset 0 -1px 0 0 var(--primary)' : 'unset'};
  color: ${({ activeTab }) =>
    activeTab ? 'var(--primary)' : 'var(--primary-light)'};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`
