import * as React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLabel = styled.label`
  font-weight: 500;
  margin: 4px;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
`

const Error = styled.div`
  color: red;
`

export const TextareaBase = styled.textarea`
  resize: none;
  border: var(--border-size) solid var(--primary-light);
  border-radius: var(--border-radius);
  height: calc(var(--spacing) * 14);
  padding: calc(var(--spacing) * 4);
  transition: all 300ms;
  &::placeholder {
    color: var(--primary-light);
  }
  &:focus {
    border-color: var(--primary);
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus-visible {
    border-color: var(--primary);
    outline: none;
  }
  [type='number'] {
    -moz-appearance: textfield;
  }
`

export const Textarea = ({
  label,
  id,
  onChange,
  value,
  placeholder,
  error,
  type = 'text',
  tooltiptext,
  appendIcon,
  tooltipPosition
}) => (
  <StyledContainer>
    {label && !appendIcon && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
    {label && appendIcon && (
      <StyledLabel htmlFor={id}>
        {label}
        <div data-position={tooltipPosition} data-tooltip={tooltiptext} className="tooltip">
          <img
            src={appendIcon}
            alt="Icon"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </div>
      </StyledLabel>
    )}
    <TextareaBase
      id={id}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      type={type}
    />
    {error && <Error>{error}</Error>}
  </StyledContainer>
)
